/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Hero from '../../components/Hero/Hero';
import More from '../../widgets/More/More';
import Section from './Section/Section';
import RealisationCard from '../../components/RealisationCard';
import Page from '../../components/Page/Page';

const TitleSection = styled(Section)`
    ${tw`pt-24`}
`;

const GridSection = styled(Section)`
    ${tw`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pt-8`}
`;

const Title = styled.h2`
    ${tw`text-center font-bold font-montserrat text-xl md:text-3xl uppercase mb-1 tracking-wide`}
    &:before {
      display: inline-block;
      border-top: solid 2px #707070;
      width: 3.75rem;
      content: '';
      margin-right: 1.25rem;
      transform: translateY(-0.5rem);
    }
`;

const SubTitle = styled.p`
    ${tw`text-center font-medium font-montserrat text-sm tracking-wide`}
`;

const Realisations = ({ pageContext: { seo, title } }) => {
  const data = useStaticQuery(graphql`
  query MyRealisationsQuery {
    allContentfulRealisation(sort: {order: DESC, fields: projectDate}) {
      edges {
        node {
          id
          title
          subTitle
          slug
          thumbnail {
            fixed(height: 800, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
    }
  }
  
  `);

  return (
    <Page
      seo={seo}
      header={() => (
        <Hero title={title} />
      )}
      main={() => (
        <>
          <TitleSection>
            <Title>Architecture d'intérieur & décoration</Title>
            <SubTitle>Découvrez toutes mes réalisations.</SubTitle>
          </TitleSection>
          <GridSection>
            {data.allContentfulRealisation.edges.map(
              ({ node }) => (
                <RealisationCard realisation={{
                  slug: node.slug,
                  id: node.id,
                  image: node.thumbnail,
                  title: node.title,
                  subTitle: node.subTitle,
                }}
                />
              ),
            )}
          </GridSection>
          <More
            title="vous avez un projet à me confier ?"
            buttonLabel="ME CONTACTER"
            buttonSrc="/contact"
          />
        </>
      )}
    />
  );
};

Realisations.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default Realisations;
