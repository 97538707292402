/* eslint-disable react/prop-types */
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Facebook from './Icons/Facebook';
import Instagram from './Icons/Instagram';
import Pinterest from './Icons/Pinterest';
import RightArrow from './Icons/RightArrow';

const RealisationImage = styled.img`
    ${tw`flex h-80 md:h-100 object-cover transition duration-200 hover:opacity-20 w-full`}
`;

const Card = styled.article``;

const CardFooter = styled.div`
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1.25rem;
  transform: translateY(-33%);
  width: 90%;
  height: 10rem;
  justify-content: space-between;
`;

const CardFooterBody = styled.div`
`;

const CardFooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RealisatonTag = styled.p`
  ${tw`font-lato italic text-xs text-pink tracking-wide`}
`;

const RealisationTitle = styled.p`
    ${tw`font-lato font-bold uppercase mt-2 tracking-widest`}
`;

const RealisationSubTitle = styled.p`
    ${tw`font-lato text-xs tracking-wide`}
`;

const Socials = styled.div`
  ${tw`flex `}
`;

const SocialItem = styled.a`
    ${tw`mr-2`}
`;

const socials = [
  { type: 'facebook', icon: Facebook, createShareUrl: (url) => `//www.facebook.com/sharer.php?u=${url}` },
  { type: 'instagram', icon: Instagram, createShareUrl: () => '#' },
  { type: 'pinterest', icon: Pinterest, createShareUrl: (url) => `//pinterest.com/pin/create/button?url=${url}` },
];

const RealisationCard = ({
  realisation: {
    slug, id, image, title, subTitle,
  },
}) => (
  <Card>
    <Link to={`/${slug}`} key={id} className="bg-black flex">
      <RealisationImage src={image.fixed.src} alt="" />
    </Link>
    <CardFooter>
      <CardFooterBody>
        <RealisatonTag>Architecture - Décoration</RealisatonTag>
        <RealisationTitle>{title}</RealisationTitle>
        <RealisationSubTitle>{subTitle}</RealisationSubTitle>
      </CardFooterBody>
      <CardFooterBottom>
        <Socials>
          {socials.map(({ type, icon, createShareUrl }) => {
            const Icon = icon;
            return (
              <SocialItem href={createShareUrl(`https://annesophielagoet.fr/${slug}`)} target="_blank" key={type} aria-label={type}>
                <Icon width="1rem" height="1rem" color="black" hover />
              </SocialItem>
            );
          })}
        </Socials>
        <Link to={`/${slug}`} key={id}>
          <RightArrow />
        </Link>
      </CardFooterBottom>
    </CardFooter>
  </Card>
);

export default RealisationCard;
