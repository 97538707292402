import styled from 'styled-components';
import tw from 'twin.macro';

const Divide = styled.div`
    ${tw`relative text-center mb-7 w-full`}
  &:after {
    ${tw`border border-solid border-color-4`}
    ${tw`absolute left-0 right-0`}
    content: '';
    z-index: -1;
    top: 50%;
  }
`;

export default Divide;
