import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Facebook from '../Icons/Facebook';
import Instagram from '../Icons/Instagram';
import Pinterest from '../Icons/Pinterest';
import NavigationBar from '../../widgets/NavigationBar/NavigationBar';

const HeroContainer = styled.div`
    ${tw`flex flex-col overflow-hidden`}
`;
const Container = styled.div`
    ${tw`flex flex-col text-white justify-center items-center bg-black-100 w-full order-1 p-16`}
    height: calc((100% - 4rem) / 2);
`;

const PreTitleContainer = styled.div`
    ${tw`flex`}
`;

const PreTitle = styled.div`
    ${tw`font-montserrat font-light uppercase font-light text-sm mx-2 mb-4 tracking-widest border-b border-solid border-white leading-9`}
`;

const TitleTag = styled.h1`
  ${tw`text-center`}
`;

const Title = styled.span`
    ${tw`font-lato uppercase font-bold text-4xl md:text-5xl tracking-wider mb-1`}
`;

const Socials = styled.div`
    ${tw`flex mt-10`}
`;

const Social = styled.div`
    ${tw`flex items-center mx-2 md:mx-8`}
`;

const SocialText = styled.span`
    ${tw`font-openSans font-semibold text-xs uppercase tracking-widest ml-2`}
`;

const NavigationBarContainer = styled.div`
  ${tw`order-3 md:order-2`}
`;

const socials = [
  { component: Facebook, text: 'Like it' },
  { component: Instagram, text: 'Repost it' },
  { component: Pinterest, text: 'Pint it' },
];

const Hero = ({
  title,
}) => (
  <HeroContainer>
    <Container>
      <PreTitleContainer>
        <PreTitle>Architecture</PreTitle>
        <PreTitle>Decoration</PreTitle>
      </PreTitleContainer>
      <TitleTag>
        <Title>{title}</Title>
      </TitleTag>
      <Socials>
        {socials.map(({ component, text }) => {
          const Component = component;
          return (
            <Social>
              <Component color="white" width="24px" height="24px" />
              <SocialText>{text}</SocialText>
            </Social>
          );
        })}
      </Socials>
    </Container>
    <NavigationBarContainer>
      <NavigationBar />
    </NavigationBarContainer>
  </HeroContainer>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  projectInfo: PropTypes.shape({
    description: PropTypes.string,
    place: PropTypes.string,
    surface: PropTypes.string,
  }).isRequired,
};

export default Hero;
