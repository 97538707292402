/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';

const event = ({
  action, category, label, value,
}) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const useTrackingEvent = () => {
  const trackingHandler = useCallback((eventObject) => () => event(eventObject), []);
  return trackingHandler;
};
