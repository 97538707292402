import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../components/Button/StyledButton';
import Section from './Section/Section';
import Divide from './Divide/Divide';
import Title from './Title/Title';
import { useTrackingEvent } from '../../utils/tracking';

const More = ({
  title, buttonLabel, buttonSrc, trackingEvent, divider,
}) => {
  const trackingEventHandler = useTrackingEvent();
  return (
    <Section>
      { divider && (
      <Divide>
        <Title>{title}</Title>
      </Divide>
      )}
      <Button as={Link} to={buttonSrc} onClick={trackingEventHandler(trackingEvent)}>
        {buttonLabel}
      </Button>
    </Section>
  );
};

More.defaultProps = {
  trackingEvent: {},
  divider: true,
};

More.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  trackingEvent: PropTypes.object,
};

export default More;
