import React from 'react';

const RightArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.923" height="7.589" viewBox="0 0 10.923 7.589">
    <g id="Groupe_625" data-name="Groupe 625" transform="translate(-402.638 -1154.972)">
      <g id="Groupe_618" data-name="Groupe 618" transform="translate(43.712 7.627)">
        <line id="Ligne_18" data-name="Ligne 18" x2="9.112" transform="translate(359.676 1151.139)" fill="none" stroke="#13191f" strokeLinecap="round" strokeWidth="1.5" />
        <line id="Ligne_19" data-name="Ligne 19" x2="2.734" y2="2.734" transform="translate(366.055 1148.406)" fill="none" stroke="#13191f" strokeLinecap="round" strokeWidth="1.5" />
        <line id="Ligne_20" data-name="Ligne 20" y1="2.734" x2="2.734" transform="translate(366.055 1151.139)" fill="none" stroke="#13191f" strokeLinecap="round" strokeWidth="1.5" />
      </g>
    </g>
  </svg>
);

export default RightArrow;
